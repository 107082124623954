<template>
  <div class="container">
    <div class="row d-flex justify-content-around align-items-center">
      <div class="col-3 text-left">
        <img class="w-75" src="/assets/images/logo.svg" />
      </div>
      <div class="col-6 text-center">
      </div>
    </div>
    <div class="row mt-5 text-center">
      <div class="col-12">
        <h2 class="header-title mb-0 mt-3 text-center text-uppercase">Factura</h2>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mt-5">
      <div class="col-4"></div>
      <div class="col-4"></div>
      <div class="col-4 text-right"></div>
    </div>
    <div class="row d-flex justify-content-between align-items-top mt-5">
      <div class="col-4">
        <div class="d-flex justify-content-between align-items-center mb-1">
          <p class="font-weight-light">Factura :</p>
          <p class="font-weight-bold">
            # FACT-00{{invoice.id}}
          </p>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <p class="font-weight-light">Fecha Factura :</p>
          <p class="font-weight-bold">
            {{ invoice.due_date | moment('DD-MM-YYYY') }}
          </p>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-1">
          <p class="font-weight-light">Fecha de Vencimiento :</p>
          <p class="font-weight-bold">
            {{ invoice.payment_date | moment('DD-MM-YYYY') }}
          </p>
        </div>
      </div>
      <div class="col-3"></div>
      <div class="col-5">
        <div class="row">
          <div class="col-12 text-right">
            <p class="h4">Facturado a:</p>
          </div>
          <div class="col-12">
            <div v-if="invoice.record.client" class="font-weight-bold">
              <p class="h4 text-right" v-if="invoice.record.client.type_id === 2">
                {{ invoice.record.client.commercial_name | uppercase }}
              </p>
              <p class="h3 text-right" v-else>
                {{ invoice.record.client.first_name | uppercase }} {{ invoice.record.client.last_name | uppercase }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-5">
      <div class="col-12 my-5">
        <table class="table border">
          <thead class="bg-secondary text-black">
            <tr>
              <th>#</th>
              <th>Descripción</th>
              <th>Qty</th>
              <th>Precio</th>
              <th>Monto</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in invoice.items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.description | uppercase }}</td>
              <td>{{ item.qty }}</td>
              <td>{{ item.amount | formatMoney }}</td>
              <td>{{ (item.amount * item.qty) | formatMoney }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="row d-flex justify-content-between align-items-center mt-4">
      <div class="col-4"></div>
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row mb-3">
          <div class="col-6 font-weight-light">Sub Total</div>
          <div class="col-6 text-right">{{invoice.subtotal | formatMoney}}</div>
        </div>
        <div class="row mb-3">
          <div class="col-6">Impuestos</div>
          <div class="col-6 text-right">{{invoice.itbis | formatMoney}}</div>
        </div>
        <div class="row mb-3">
          <div class="col-6">Total</div>
          <div class="col-6 text-right">{{invoice.total | formatMoney}}</div>
        </div>
        <div class="row mb-3 d-print-none">
          <div class="col-6 font-weight-light">Pagos</div>
          <div class="col-6 text-success font-weight-bold text-right"><em>{{invoice.payments | getInvoicePayments | formatMoney }}</em></div>
        </div>
        <div class="row d-print-none">
          <div class="col-6 font-weight-light">Balance Pendiente</div>
          <div class="col-6 text-danger text-right">{{invoice | getInvoiceBalance | formatMoney}}</div>
        </div>
      </div>
    </div>
    <div class="row mt-5 d-flex justify-content-center align-items-center flex-column">
      <div class="mt-5 col-3 text-center">
        <a onclick="window.print()" href="#" class="d-print-none btn btn-info d-block">Imprimir</a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'InvoiceReceipt',
  props: ['id'],
  data () {
    return {
      invoice: {}
    }
  },
  methods: {
    ...mapActions({
      show: 'Invoice/show'
    })
  },
  mounted () {
    this.show({
      id: this.id
    })
      .then(response => {
        this.invoice = response.data
      })
  }
}
</script>
